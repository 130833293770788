// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, initializeAuth, OAuthProvider } from 'firebase/auth'
import { getStorage } from "@firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAtsNROyb1ebu8qj98swEC4h24usInQ-cw",
    authDomain: "stamp-5db44.firebaseapp.com",
    projectId: "stamp-5db44",
    storageBucket: "stamp-5db44.appspot.com",
    messagingSenderId: "105710516225",
    appId: "1:105710516225:web:64ef54f456ef96d966a800",
    measurementId: "G-2QREZ8JPHB"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

//database
export const db = getFirestore(app);

//auth
export const auth = getAuth(app);

//storage
export const storage = getStorage(app);

//google provider
export const googleProvider  = new GoogleAuthProvider();

//apple provider
export const appleProvider = new OAuthProvider('apple.com');